import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _5b869014 = () => interopDefault(import('../pages/add-home.vue' /* webpackChunkName: "pages/add-home" */))
const _395e218b = () => interopDefault(import('../pages/agree-terms.vue' /* webpackChunkName: "pages/agree-terms" */))
const _e9ba0ab6 = () => interopDefault(import('../pages/confirm.vue' /* webpackChunkName: "pages/confirm" */))
const _7bf1e44d = () => interopDefault(import('../pages/doctor/index.vue' /* webpackChunkName: "pages/doctor/index" */))
const _5d045da7 = () => interopDefault(import('../pages/group/index.vue' /* webpackChunkName: "pages/group/index" */))
const _4da411a1 = () => interopDefault(import('../pages/hospitals/index.vue' /* webpackChunkName: "pages/hospitals/index" */))
const _c67c4e72 = () => interopDefault(import('../pages/invitation/index.vue' /* webpackChunkName: "pages/invitation/index" */))
const _1b0b760e = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _37f74b2e = () => interopDefault(import('../pages/mypage/index.vue' /* webpackChunkName: "pages/mypage/index" */))
const _34018cfa = () => interopDefault(import('../pages/notices/index.vue' /* webpackChunkName: "pages/notices/index" */))
const _e5ce3a4e = () => interopDefault(import('../pages/notification/index.vue' /* webpackChunkName: "pages/notification/index" */))
const _ece3e8cc = () => interopDefault(import('../pages/passwordSetting.vue' /* webpackChunkName: "pages/passwordSetting" */))
const _0a583494 = () => interopDefault(import('../pages/posting_information.vue' /* webpackChunkName: "pages/posting_information" */))
const _fd99fde4 = () => interopDefault(import('../pages/profile.vue' /* webpackChunkName: "pages/profile" */))
const _3a84e0d8 = () => interopDefault(import('../pages/regist-confirm.vue' /* webpackChunkName: "pages/regist-confirm" */))
const _5fa5ee5a = () => interopDefault(import('../pages/retry-register.vue' /* webpackChunkName: "pages/retry-register" */))
const _14f97ee0 = () => interopDefault(import('../pages/rooms/index.vue' /* webpackChunkName: "pages/rooms/index" */))
const _f255355a = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _da858e7a = () => interopDefault(import('../pages/signup.vue' /* webpackChunkName: "pages/signup" */))
const _093cdda8 = () => interopDefault(import('../pages/terms.vue' /* webpackChunkName: "pages/terms" */))
const _a4484aec = () => interopDefault(import('../pages/unreceived_auth_code.vue' /* webpackChunkName: "pages/unreceived_auth_code" */))
const _76628e47 = () => interopDefault(import('../pages/welcome.vue' /* webpackChunkName: "pages/welcome" */))
const _54afae2c = () => interopDefault(import('../pages/business-card/holder/index.vue' /* webpackChunkName: "pages/business-card/holder/index" */))
const _4a53fd04 = () => interopDefault(import('../pages/business-card/requests/index.vue' /* webpackChunkName: "pages/business-card/requests/index" */))
const _297cc633 = () => interopDefault(import('../pages/contact/complete.vue' /* webpackChunkName: "pages/contact/complete" */))
const _56f2a394 = () => interopDefault(import('../pages/contact/confirm.vue' /* webpackChunkName: "pages/contact/confirm" */))
const _f2f8b584 = () => interopDefault(import('../pages/contact/form.vue' /* webpackChunkName: "pages/contact/form" */))
const _0b56211e = () => interopDefault(import('../pages/doctor/new/index.vue' /* webpackChunkName: "pages/doctor/new/index" */))
const _a3ed0286 = () => interopDefault(import('../pages/doctor/search.vue' /* webpackChunkName: "pages/doctor/search" */))
const _754172b5 = () => interopDefault(import('../pages/group/new.vue' /* webpackChunkName: "pages/group/new" */))
const _7c76cbce = () => interopDefault(import('../pages/hospitals/edit/index.vue' /* webpackChunkName: "pages/hospitals/edit/index" */))
const _129cfbe9 = () => interopDefault(import('../pages/hospitals/search.vue' /* webpackChunkName: "pages/hospitals/search" */))
const _4e1f2058 = () => interopDefault(import('../pages/invitation/complete.vue' /* webpackChunkName: "pages/invitation/complete" */))
const _77ca0667 = () => interopDefault(import('../pages/lists/hospitals/index.vue' /* webpackChunkName: "pages/lists/hospitals/index" */))
const _16af2c6a = () => interopDefault(import('../pages/lists/new.vue' /* webpackChunkName: "pages/lists/new" */))
const _5910f9b2 = () => interopDefault(import('../pages/medical/confirm.vue' /* webpackChunkName: "pages/medical/confirm" */))
const _6cf0ece0 = () => interopDefault(import('../pages/medical/profile.vue' /* webpackChunkName: "pages/medical/profile" */))
const _c556cffe = () => interopDefault(import('../pages/medical/signup.vue' /* webpackChunkName: "pages/medical/signup" */))
const _c0adcef6 = () => interopDefault(import('../pages/migration/complete.vue' /* webpackChunkName: "pages/migration/complete" */))
const _587bbc4e = () => interopDefault(import('../pages/migration/registered.vue' /* webpackChunkName: "pages/migration/registered" */))
const _c6d9174e = () => interopDefault(import('../pages/ms/search/index.vue' /* webpackChunkName: "pages/ms/search/index" */))
const _4845cc21 = () => interopDefault(import('../pages/mypage/search.vue' /* webpackChunkName: "pages/mypage/search" */))
const _57a9eb54 = () => interopDefault(import('../pages/notices/new/index.vue' /* webpackChunkName: "pages/notices/new/index" */))
const _8f60e3a4 = () => interopDefault(import('../pages/rooms/new.vue' /* webpackChunkName: "pages/rooms/new" */))
const _4b6aae42 = () => interopDefault(import('../pages/setting/mail_magazine.vue' /* webpackChunkName: "pages/setting/mail_magazine" */))
const _2e547f3f = () => interopDefault(import('../pages/top/medical/index.vue' /* webpackChunkName: "pages/top/medical/index" */))
const _d532776a = () => interopDefault(import('../pages/top/ms.vue' /* webpackChunkName: "pages/top/ms" */))
const _dc8b49e8 = () => interopDefault(import('../pages/doctor/new/confirm.vue' /* webpackChunkName: "pages/doctor/new/confirm" */))
const _f4798790 = () => interopDefault(import('../pages/doctor/new/profile/index.vue' /* webpackChunkName: "pages/doctor/new/profile/index" */))
const _5a5c3d40 = () => interopDefault(import('../pages/doctor/new/select.vue' /* webpackChunkName: "pages/doctor/new/select" */))
const _187e79b2 = () => interopDefault(import('../pages/group/member/add.vue' /* webpackChunkName: "pages/group/member/add" */))
const _a1be8e06 = () => interopDefault(import('../pages/hospitals/edit/bed.vue' /* webpackChunkName: "pages/hospitals/edit/bed" */))
const _2ab5deae = () => interopDefault(import('../pages/hospitals/edit/medical_staff.vue' /* webpackChunkName: "pages/hospitals/edit/medical_staff" */))
const _f533677a = () => interopDefault(import('../pages/hospitals/edit/open_bed/index.vue' /* webpackChunkName: "pages/hospitals/edit/open_bed/index" */))
const _6e56f66e = () => interopDefault(import('../pages/hospitals/edit/outpatient/index.vue' /* webpackChunkName: "pages/hospitals/edit/outpatient/index" */))
const _8a629548 = () => interopDefault(import('../pages/hospitals/edit/patient_date.vue' /* webpackChunkName: "pages/hospitals/edit/patient_date" */))
const _7336b15e = () => interopDefault(import('../pages/hospitals/edit/result.vue' /* webpackChunkName: "pages/hospitals/edit/result" */))
const _3f2e9c14 = () => interopDefault(import('../pages/lists/hospitals/add.vue' /* webpackChunkName: "pages/lists/hospitals/add" */))
const _10f75dea = () => interopDefault(import('../pages/notices/new/select.vue' /* webpackChunkName: "pages/notices/new/select" */))
const _91c5705e = () => interopDefault(import('../pages/doctor/new/profile/doctorExpertCategory.vue' /* webpackChunkName: "pages/doctor/new/profile/doctorExpertCategory" */))
const _532176fd = () => interopDefault(import('../pages/doctor/new/profile/pending.vue' /* webpackChunkName: "pages/doctor/new/profile/pending" */))
const _2e434d75 = () => interopDefault(import('../pages/doctor/new/profile/searchPaperMeshList.vue' /* webpackChunkName: "pages/doctor/new/profile/searchPaperMeshList" */))
const _67335026 = () => interopDefault(import('../pages/doctor/new/profile/surgeryMeshList.vue' /* webpackChunkName: "pages/doctor/new/profile/surgeryMeshList" */))
const _68c0ef10 = () => interopDefault(import('../pages/doctor/new/profile/unacceptableMeshList.vue' /* webpackChunkName: "pages/doctor/new/profile/unacceptableMeshList" */))
const _d3d4b1c4 = () => interopDefault(import('../pages/hospitals/edit/outpatient/edit.vue' /* webpackChunkName: "pages/hospitals/edit/outpatient/edit" */))
const _37185fce = () => interopDefault(import('../pages/doctor/new/profile/editHospital/_id.vue' /* webpackChunkName: "pages/doctor/new/profile/editHospital/_id" */))
const _10def92a = () => interopDefault(import('../pages/hospitals/edit/open_bed/_id.vue' /* webpackChunkName: "pages/hospitals/edit/open_bed/_id" */))
const _20d80601 = () => interopDefault(import('../pages/group/notice/_id/index.vue' /* webpackChunkName: "pages/group/notice/_id/index" */))
const _7f24768f = () => interopDefault(import('../pages/lists/hospitals/_id.vue' /* webpackChunkName: "pages/lists/hospitals/_id" */))
const _c85d9d6c = () => interopDefault(import('../pages/group/notice/_id/reaction.vue' /* webpackChunkName: "pages/group/notice/_id/reaction" */))
const _237610b8 = () => interopDefault(import('../pages/doctor/_id/index.vue' /* webpackChunkName: "pages/doctor/_id/index" */))
const _4ccf65dc = () => interopDefault(import('../pages/group/_id/index.vue' /* webpackChunkName: "pages/group/_id/index" */))
const _56c7ef6e = () => interopDefault(import('../pages/hospitals/_id.vue' /* webpackChunkName: "pages/hospitals/_id" */))
const _62ad60ef = () => interopDefault(import('../pages/invitation/_id.vue' /* webpackChunkName: "pages/invitation/_id" */))
const _0d08367f = () => interopDefault(import('../pages/ms/_id/index.vue' /* webpackChunkName: "pages/ms/_id/index" */))
const _6fc9daee = () => interopDefault(import('../pages/notices/_id/index.vue' /* webpackChunkName: "pages/notices/_id/index" */))
const _0f03c781 = () => interopDefault(import('../pages/notification/_id.vue' /* webpackChunkName: "pages/notification/_id" */))
const _6e515d3d = () => interopDefault(import('../pages/papers/_id.vue' /* webpackChunkName: "pages/papers/_id" */))
const _633829cb = () => interopDefault(import('../pages/rooms/_id/index.vue' /* webpackChunkName: "pages/rooms/_id/index" */))
const _2768b9d2 = () => interopDefault(import('../pages/doctor/_id/edit/index.vue' /* webpackChunkName: "pages/doctor/_id/edit/index" */))
const _5fbcebfa = () => interopDefault(import('../pages/group/_id/edit.vue' /* webpackChunkName: "pages/group/_id/edit" */))
const _4ed56458 = () => interopDefault(import('../pages/group/_id/history.vue' /* webpackChunkName: "pages/group/_id/history" */))
const _fd1cfce6 = () => interopDefault(import('../pages/group/_id/member/index.vue' /* webpackChunkName: "pages/group/_id/member/index" */))
const _b645fca0 = () => interopDefault(import('../pages/ms/_id/edit/index.vue' /* webpackChunkName: "pages/ms/_id/edit/index" */))
const _6aebcaa1 = () => interopDefault(import('../pages/notices/_id/edit/index.vue' /* webpackChunkName: "pages/notices/_id/edit/index" */))
const _927bd7be = () => interopDefault(import('../pages/rooms/_id/edit.vue' /* webpackChunkName: "pages/rooms/_id/edit" */))
const _2867fb55 = () => interopDefault(import('../pages/rooms/_id/members/index.vue' /* webpackChunkName: "pages/rooms/_id/members/index" */))
const _02d2366e = () => interopDefault(import('../pages/doctor/_id/edit/category.vue' /* webpackChunkName: "pages/doctor/_id/edit/category" */))
const _0c0c6712 = () => interopDefault(import('../pages/doctor/_id/edit/doctorExpertCategory.vue' /* webpackChunkName: "pages/doctor/_id/edit/doctorExpertCategory" */))
const _ca5f5e3e = () => interopDefault(import('../pages/doctor/_id/edit/email.vue' /* webpackChunkName: "pages/doctor/_id/edit/email" */))
const _39a87516 = () => interopDefault(import('../pages/doctor/_id/edit/papers/index.vue' /* webpackChunkName: "pages/doctor/_id/edit/papers/index" */))
const _3942c214 = () => interopDefault(import('../pages/doctor/_id/edit/searchPaperMeshList.vue' /* webpackChunkName: "pages/doctor/_id/edit/searchPaperMeshList" */))
const _739f2a7a = () => interopDefault(import('../pages/doctor/_id/edit/specialty.vue' /* webpackChunkName: "pages/doctor/_id/edit/specialty" */))
const _162bec45 = () => interopDefault(import('../pages/doctor/_id/edit/surgeryMeshList.vue' /* webpackChunkName: "pages/doctor/_id/edit/surgeryMeshList" */))
const _849fe35e = () => interopDefault(import('../pages/doctor/_id/edit/unacceptableMeshList.vue' /* webpackChunkName: "pages/doctor/_id/edit/unacceptableMeshList" */))
const _b543734e = () => interopDefault(import('../pages/group/_id/notice/new.vue' /* webpackChunkName: "pages/group/_id/notice/new" */))
const _65f1683d = () => interopDefault(import('../pages/notices/_id/edit/select.vue' /* webpackChunkName: "pages/notices/_id/edit/select" */))
const _3c3b0a64 = () => interopDefault(import('../pages/rooms/_id/members/add.vue' /* webpackChunkName: "pages/rooms/_id/members/add" */))
const _7cdbadd0 = () => interopDefault(import('../pages/doctor/_id/edit/papers/delete.vue' /* webpackChunkName: "pages/doctor/_id/edit/papers/delete" */))
const _5f5ff074 = () => interopDefault(import('../pages/doctor/_id/edit/hospitals/_index.vue' /* webpackChunkName: "pages/doctor/_id/edit/hospitals/_index" */))
const _5d149099 = () => interopDefault(import('../pages/rooms/_id/medias/_mediaId.vue' /* webpackChunkName: "pages/rooms/_id/medias/_mediaId" */))
const _5163d2f7 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/add-home",
    component: _5b869014,
    name: "add-home"
  }, {
    path: "/agree-terms",
    component: _395e218b,
    name: "agree-terms"
  }, {
    path: "/confirm",
    component: _e9ba0ab6,
    name: "confirm"
  }, {
    path: "/doctor",
    component: _7bf1e44d,
    name: "doctor"
  }, {
    path: "/group",
    component: _5d045da7,
    name: "group"
  }, {
    path: "/hospitals",
    component: _4da411a1,
    name: "hospitals"
  }, {
    path: "/invitation",
    component: _c67c4e72,
    name: "invitation"
  }, {
    path: "/login",
    component: _1b0b760e,
    name: "login"
  }, {
    path: "/mypage",
    component: _37f74b2e,
    name: "mypage"
  }, {
    path: "/notices",
    component: _34018cfa,
    name: "notices"
  }, {
    path: "/notification",
    component: _e5ce3a4e,
    name: "notification"
  }, {
    path: "/passwordSetting",
    component: _ece3e8cc,
    name: "passwordSetting"
  }, {
    path: "/posting_information",
    component: _0a583494,
    name: "posting_information"
  }, {
    path: "/profile",
    component: _fd99fde4,
    name: "profile"
  }, {
    path: "/regist-confirm",
    component: _3a84e0d8,
    name: "regist-confirm"
  }, {
    path: "/retry-register",
    component: _5fa5ee5a,
    name: "retry-register"
  }, {
    path: "/rooms",
    component: _14f97ee0,
    name: "rooms"
  }, {
    path: "/search",
    component: _f255355a,
    name: "search"
  }, {
    path: "/signup",
    component: _da858e7a,
    name: "signup"
  }, {
    path: "/terms",
    component: _093cdda8,
    name: "terms"
  }, {
    path: "/unreceived_auth_code",
    component: _a4484aec,
    name: "unreceived_auth_code"
  }, {
    path: "/welcome",
    component: _76628e47,
    name: "welcome"
  }, {
    path: "/business-card/holder",
    component: _54afae2c,
    name: "business-card-holder"
  }, {
    path: "/business-card/requests",
    component: _4a53fd04,
    name: "business-card-requests"
  }, {
    path: "/contact/complete",
    component: _297cc633,
    name: "contact-complete"
  }, {
    path: "/contact/confirm",
    component: _56f2a394,
    name: "contact-confirm"
  }, {
    path: "/contact/form",
    component: _f2f8b584,
    name: "contact-form"
  }, {
    path: "/doctor/new",
    component: _0b56211e,
    name: "doctor-new"
  }, {
    path: "/doctor/search",
    component: _a3ed0286,
    name: "doctor-search"
  }, {
    path: "/group/new",
    component: _754172b5,
    name: "group-new"
  }, {
    path: "/hospitals/edit",
    component: _7c76cbce,
    name: "hospitals-edit"
  }, {
    path: "/hospitals/search",
    component: _129cfbe9,
    name: "hospitals-search"
  }, {
    path: "/invitation/complete",
    component: _4e1f2058,
    name: "invitation-complete"
  }, {
    path: "/lists/hospitals",
    component: _77ca0667,
    name: "lists-hospitals"
  }, {
    path: "/lists/new",
    component: _16af2c6a,
    name: "lists-new"
  }, {
    path: "/medical/confirm",
    component: _5910f9b2,
    name: "medical-confirm"
  }, {
    path: "/medical/profile",
    component: _6cf0ece0,
    name: "medical-profile"
  }, {
    path: "/medical/signup",
    component: _c556cffe,
    name: "medical-signup"
  }, {
    path: "/migration/complete",
    component: _c0adcef6,
    name: "migration-complete"
  }, {
    path: "/migration/registered",
    component: _587bbc4e,
    name: "migration-registered"
  }, {
    path: "/ms/search",
    component: _c6d9174e,
    name: "ms-search"
  }, {
    path: "/mypage/search",
    component: _4845cc21,
    name: "mypage-search"
  }, {
    path: "/notices/new",
    component: _57a9eb54,
    name: "notices-new"
  }, {
    path: "/rooms/new",
    component: _8f60e3a4,
    name: "rooms-new"
  }, {
    path: "/setting/mail_magazine",
    component: _4b6aae42,
    name: "setting-mail_magazine"
  }, {
    path: "/top/medical",
    component: _2e547f3f,
    name: "top-medical"
  }, {
    path: "/top/ms",
    component: _d532776a,
    name: "top-ms"
  }, {
    path: "/doctor/new/confirm",
    component: _dc8b49e8,
    name: "doctor-new-confirm"
  }, {
    path: "/doctor/new/profile",
    component: _f4798790,
    name: "doctor-new-profile"
  }, {
    path: "/doctor/new/select",
    component: _5a5c3d40,
    name: "doctor-new-select"
  }, {
    path: "/group/member/add",
    component: _187e79b2,
    name: "group-member-add"
  }, {
    path: "/hospitals/edit/bed",
    component: _a1be8e06,
    name: "hospitals-edit-bed"
  }, {
    path: "/hospitals/edit/medical_staff",
    component: _2ab5deae,
    name: "hospitals-edit-medical_staff"
  }, {
    path: "/hospitals/edit/open_bed",
    component: _f533677a,
    name: "hospitals-edit-open_bed"
  }, {
    path: "/hospitals/edit/outpatient",
    component: _6e56f66e,
    name: "hospitals-edit-outpatient"
  }, {
    path: "/hospitals/edit/patient_date",
    component: _8a629548,
    name: "hospitals-edit-patient_date"
  }, {
    path: "/hospitals/edit/result",
    component: _7336b15e,
    name: "hospitals-edit-result"
  }, {
    path: "/lists/hospitals/add",
    component: _3f2e9c14,
    name: "lists-hospitals-add"
  }, {
    path: "/notices/new/select",
    component: _10f75dea,
    name: "notices-new-select"
  }, {
    path: "/doctor/new/profile/doctorExpertCategory",
    component: _91c5705e,
    name: "doctor-new-profile-doctorExpertCategory"
  }, {
    path: "/doctor/new/profile/pending",
    component: _532176fd,
    name: "doctor-new-profile-pending"
  }, {
    path: "/doctor/new/profile/searchPaperMeshList",
    component: _2e434d75,
    name: "doctor-new-profile-searchPaperMeshList"
  }, {
    path: "/doctor/new/profile/surgeryMeshList",
    component: _67335026,
    name: "doctor-new-profile-surgeryMeshList"
  }, {
    path: "/doctor/new/profile/unacceptableMeshList",
    component: _68c0ef10,
    name: "doctor-new-profile-unacceptableMeshList"
  }, {
    path: "/hospitals/edit/outpatient/edit",
    component: _d3d4b1c4,
    name: "hospitals-edit-outpatient-edit"
  }, {
    path: "/doctor/new/profile/editHospital/:id?",
    component: _37185fce,
    name: "doctor-new-profile-editHospital-id"
  }, {
    path: "/hospitals/edit/open_bed/:id",
    component: _10def92a,
    name: "hospitals-edit-open_bed-id"
  }, {
    path: "/group/notice/:id",
    component: _20d80601,
    name: "group-notice-id"
  }, {
    path: "/lists/hospitals/:id",
    component: _7f24768f,
    name: "lists-hospitals-id"
  }, {
    path: "/group/notice/:id?/reaction",
    component: _c85d9d6c,
    name: "group-notice-id-reaction"
  }, {
    path: "/doctor/:id",
    component: _237610b8,
    name: "doctor-id"
  }, {
    path: "/group/:id",
    component: _4ccf65dc,
    name: "group-id"
  }, {
    path: "/hospitals/:id",
    component: _56c7ef6e,
    name: "hospitals-id"
  }, {
    path: "/invitation/:id",
    component: _62ad60ef,
    name: "invitation-id"
  }, {
    path: "/ms/:id",
    component: _0d08367f,
    name: "ms-id"
  }, {
    path: "/notices/:id",
    component: _6fc9daee,
    name: "notices-id"
  }, {
    path: "/notification/:id",
    component: _0f03c781,
    name: "notification-id"
  }, {
    path: "/papers/:id?",
    component: _6e515d3d,
    name: "papers-id"
  }, {
    path: "/rooms/:id",
    component: _633829cb,
    name: "rooms-id"
  }, {
    path: "/doctor/:id/edit",
    component: _2768b9d2,
    name: "doctor-id-edit"
  }, {
    path: "/group/:id/edit",
    component: _5fbcebfa,
    name: "group-id-edit"
  }, {
    path: "/group/:id/history",
    component: _4ed56458,
    name: "group-id-history"
  }, {
    path: "/group/:id/member",
    component: _fd1cfce6,
    name: "group-id-member"
  }, {
    path: "/ms/:id?/edit",
    component: _b645fca0,
    name: "ms-id-edit"
  }, {
    path: "/notices/:id/edit",
    component: _6aebcaa1,
    name: "notices-id-edit"
  }, {
    path: "/rooms/:id/edit",
    component: _927bd7be,
    name: "rooms-id-edit"
  }, {
    path: "/rooms/:id/members",
    component: _2867fb55,
    name: "rooms-id-members"
  }, {
    path: "/doctor/:id/edit/category",
    component: _02d2366e,
    name: "doctor-id-edit-category"
  }, {
    path: "/doctor/:id/edit/doctorExpertCategory",
    component: _0c0c6712,
    name: "doctor-id-edit-doctorExpertCategory"
  }, {
    path: "/doctor/:id/edit/email",
    component: _ca5f5e3e,
    name: "doctor-id-edit-email"
  }, {
    path: "/doctor/:id/edit/papers",
    component: _39a87516,
    name: "doctor-id-edit-papers"
  }, {
    path: "/doctor/:id/edit/searchPaperMeshList",
    component: _3942c214,
    name: "doctor-id-edit-searchPaperMeshList"
  }, {
    path: "/doctor/:id/edit/specialty",
    component: _739f2a7a,
    name: "doctor-id-edit-specialty"
  }, {
    path: "/doctor/:id/edit/surgeryMeshList",
    component: _162bec45,
    name: "doctor-id-edit-surgeryMeshList"
  }, {
    path: "/doctor/:id/edit/unacceptableMeshList",
    component: _849fe35e,
    name: "doctor-id-edit-unacceptableMeshList"
  }, {
    path: "/group/:id/notice/new",
    component: _b543734e,
    name: "group-id-notice-new"
  }, {
    path: "/notices/:id/edit/select",
    component: _65f1683d,
    name: "notices-id-edit-select"
  }, {
    path: "/rooms/:id/members/add",
    component: _3c3b0a64,
    name: "rooms-id-members-add"
  }, {
    path: "/doctor/:id/edit/papers/delete",
    component: _7cdbadd0,
    name: "doctor-id-edit-papers-delete"
  }, {
    path: "/doctor/:id/edit/hospitals/:index",
    component: _5f5ff074,
    name: "doctor-id-edit-hospitals"
  }, {
    path: "/rooms/:id/medias/:mediaId?",
    component: _5d149099,
    name: "rooms-id-medias-mediaId"
  }, {
    path: "/",
    component: _5163d2f7,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
