<template>
  <div class="tab-container-wrap">
    <VAppBar
      flat
      :hide-on-scroll="$device.isMobile"
      fixed
      bottom
      app
      class="tab-container-aap-bar"
      :height="`${appBarHeight}px`"
      :style="
        offsetY ? { transform: `translateY(${offsetY}) !important` } : undefined
      "
    >
      <div ref="tabBarContainer" class="tab-container">
        <slot name="default" />
        <DoctorTabBar
          v-if="isRoleDoctor"
          :current-path="currentPath"
          :is-exist-notification="unreadNotificationCount > 0"
          :unread-chat-message-count="unreadChatMessageCount"
          :mypage-unread-count="myPageUnreadCount"
          :is-from-mypage="isFromMypage"
          @click="handleClickTab"
        />
        <CoMedicalTabBar
          v-else-if="isRoleCoMedical"
          :current-path="currentPath"
          :is-exist-notification="unreadNotificationCount > 0"
          @click="handleClickTab"
        />
        <MsTabBar
          v-else-if="isRoleMs"
          :current-path="currentPath"
          :mypage-unread-count="myPageUnreadCount"
          :unread-chat-message-count="unreadChatMessageCount"
          :is-from-mypage="isFromMypage"
          @click="handleClickTab"
        />
      </div>
      <template v-if="hasSlotButton">
        <div class="slot-button">
          <slot name="button" />
        </div>
      </template>
      <OverlayTemplate
        v-if="
          (isRoleDoctor || isRoleMs) &&
          !isExcludedPathHelperTooltip &&
          hasAgreedTermsOfServiceVersion
        "
        class="helper-overlay"
        :is-open.sync="isShowHelpTooltip"
        overlay-height="calc(100% - 79px)"
      >
        <div class="overlay-content">
          <HelperTooltip
            v-model="isShowHelpTooltip"
            class="helper-tooltip"
            :text="'名刺交換ができるようになりました'"
            bottom="79px"
            :right="isRoleDoctor ? 'calc(10vw - 24px)' : 'calc(12.8vw - 24px)'"
            @close="onCloseHelperTooltip"
          >
            <template #default>
              <div class="helper-content">
                <img
                  src="~assets/images/businesscard_tooltip_mypage.png"
                  alt="名刺交換"
                  class="helper-content__img"
                />
              </div>
            </template>
          </HelperTooltip>
          <div ref="tabBarContainer" class="tab-container">
            <div class="overlay-content__tabbar">
              <DoctorTabBar
                v-if="isRoleDoctor"
                :current-path="currentPath"
                :is-exist-notification="unreadNotificationCount > 0"
                :unread-chat-message-count="unreadChatMessageCount"
                :mypage-unread-count="myPageUnreadCount"
                :is-from-mypage="isFromMypage"
                @click="handleClickTab"
              />
              <CoMedicalTabBar
                v-else-if="isRoleCoMedical"
                :current-path="currentPath"
                :is-exist-notification="unreadNotificationCount > 0"
                @click="handleClickTab"
              />
              <MsTabBar
                v-else-if="isRoleMs"
                :current-path="currentPath"
                :mypage-unread-count="myPageUnreadCount"
                :unread-chat-message-count="unreadChatMessageCount"
                :is-from-mypage="isFromMypage"
                @click="handleClickTab"
              />
            </div>
          </div>
        </div>
      </OverlayTemplate>
      <ScrollToTopButton
        v-if="isShowScrollTopButton"
        class="scroll-top"
        :bottom="`${scrollTopButtonPosition}px`"
      />
    </VAppBar>
    <div class="appbar-spacer" :style="{ height: `${spacerHeight}px` }"></div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { DEFAULT_SCROLL_TOP_BUTTON_BUTTOM_MERGIN } from '~/helper/enum'
import constant from '~/helper/constant'
import { getStorage, setStorage } from '~/helper/storage'
import { isViewedUser } from '~/helper'
import OverlayTemplate from '~/components/commons/atoms/OverlayTemplate.vue'

export default {
  components: {
    DoctorTabBar: () => import('./DoctorTabBar'),
    MsTabBar: () => import('./MsTabBar'),
    CoMedicalTabBar: () => import('./CoMedicalTabBar'),
    ScrollToTopButton: () =>
      import('~/components/sp/common/ScrollToTopButton.vue'),
    HelperTooltip: () => import('~/components/commons/atoms/HelperTooltip.vue'),
    OverlayTemplate
  },
  props: {
    isShowScrollTopButton: {
      type: Boolean,
      default: false
    },
    offsetY: {
      type: String,
      default: undefined
    },
    slotItemHeight: {
      type: Number,
      default: undefined
    }
  },
  data() {
    return {
      isShowHelpTooltip: false,
      viewedMypageTooltipUserIds: []
    }
  },
  computed: {
    ...mapGetters([
      'isRoleDoctor',
      'isRoleCoMedical',
      'isRoleMs',
      'myPageUnreadCount',
      'currentUserId',
      'hasAgreedTermsOfServiceVersion'
    ]),
    ...mapGetters('notification', ['unreadNotificationCount']),
    ...mapGetters('chat', ['unreadChatMessageCount']),
    currentPath() {
      return this.$route.path
    },
    isFromMypage() {
      return this.$route.query?.from === 'mypage'
    },
    spacerHeight() {
      return this.hasSlotItem ? this.slotItemHeight || 78 : 0
    },
    hasSlotItem() {
      return 'default' in this.$scopedSlots
    },
    hasSlotButton() {
      return 'button' in this.$scopedSlots
    },
    appBarHeight() {
      return 79
    },
    tabContainerHeight() {
      return this.hasSlotItem
        ? this.appBarHeight + (this.slotItemHeight || 78)
        : this.appBarHeight
    },
    scrollTopButtonPosition() {
      return this.tabContainerHeight + DEFAULT_SCROLL_TOP_BUTTON_BUTTOM_MERGIN
    },
    isExcludedPathHelperTooltip() {
      const currentPath = this.currentPath
      if (currentPath.startsWith('/group/notice/')) return true
      return ['/mypage'].includes(this.currentPath)
    }
  },
  mounted() {
    this.$nextTick(() => {
      const localValue = getStorage(constant.shownNewMypageHelpTooltipKey)
      this.viewedMypageTooltipUserIds = Array.isArray(localValue)
        ? localValue
        : []
      this.isShowHelpTooltip = !isViewedUser(localValue, this.currentUserId)
    })
  },
  methods: {
    handleClickTab(type) {
      switch (type) {
        case 'home':
          this.$router.push('/')
          break
        case 'comedical-top':
          this.$router.push('/top/medical')
          break
        case 'ms-top':
          this.$router.push('/top/ms')
          break
        case 'list':
          this.$router.push('/lists/hospitals')
          break
        case 'search':
          this.$router.push('/search')
          break
        case 'doctor':
          this.$router.push('/doctor/search')
          break
        case 'notification':
          this.$router.push('/notification')
          break
        case 'medical-notice':
          this.$router.push('/notices')
          break
        case 'message':
          this.$router.push('/rooms')
          break
        case 'search-hospital':
          this.$router.push('/hospitals/search')
          break
        case 'group':
          this.$router.push('/group')
          break
        case 'my-page':
          this.$router.push('/mypage')
      }
    },
    onCloseHelperTooltip() {
      this.isShowHelpTooltip = false
      setStorage(constant.shownNewMypageHelpTooltipKey, [
        ...this.viewedMypageTooltipUserIds,
        this.currentUserId
      ])
    }
  }
}
</script>
<style lang="scss" scoped>
.tab-container-wrap {
  &::v-deep {
    .v-toolbar__content {
      padding: 0;
    }

    .v-app-bar--hide-shadow,
    .v-app-bar--hide-shadow.scroll-top,
    .v-app-bar--hide-shadow.slot-button {
      transform: translateY(15px) !important;
    }
    .tab-bar-container__item {
      transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1) transform,
        0.2s cubic-bezier(0.4, 0, 0.2, 1) background-color,
        0.2s cubic-bezier(0.4, 0, 0.2, 1) left,
        0.2s cubic-bezier(0.4, 0, 0.2, 1) right,
        280ms cubic-bezier(0.4, 0, 0.2, 1) box-shadow,
        0.25s cubic-bezier(0.4, 0, 0.2, 1) max-width,
        0.25s cubic-bezier(0.4, 0, 0.2, 1) width;
    }
  }

  // eslint-disable-next-line vue-scoped-css/no-unused-selector
  .v-app-bar--hide-shadow::v-deep {
    .tab-bar-container > .tab-bar-container__item {
      transform: translateY(-5px);
    }
  }
}
.tab-container {
  position: fixed;
  width: 100%;
  bottom: 0;
  right: 0;
  left: 0;
  list-style: none;
  background-color: white;
  padding: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
  box-shadow: $footer-tab-box-shadow;
}
.appbar-spacer {
  transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1) transform,
    0.2s cubic-bezier(0.4, 0, 0.2, 1) background-color,
    0.2s cubic-bezier(0.4, 0, 0.2, 1) left,
    0.2s cubic-bezier(0.4, 0, 0.2, 1) right,
    280ms cubic-bezier(0.4, 0, 0.2, 1) box-shadow,
    0.25s cubic-bezier(0.4, 0, 0.2, 1) max-width,
    0.25s cubic-bezier(0.4, 0, 0.2, 1) width;
}

// eslint-disable-next-line vue-scoped-css/no-unused-selector
.v-app-bar--hide-shadow + .appbar-spacer {
  transform: translateY(15px);
}

.tab-container-wrap:has(.v-app-bar--hide-shadow) {
  max-height: 63px;
}

.helper-tooltip {
  width: initial;
}

.helper-content {
  margin-top: 4px;
  max-height: 180px;
  height: 100%;
  width: 100%;
  max-width: 264px;

  &__img {
    width: 100%;
    max-width: 264px;
    max-height: 180px;
    height: 100%;
  }
}
</style>
