import { ColorProfile } from '~/types/api'
const colors = require('vuetify/es5/util/colors').default

const baseColorProfile: ColorProfile = {
  primaryBlue5: '#f6f7fb',
  primaryBlue10: '#eceff6',
  primaryBlue20: '#dadfee',
  primaryBlue30: '#c7cee5',
  primaryBlue40: '#b5bedd',
  primaryBlue50: '#a2aed5',
  primaryBlue60: '#909ecc',
  primaryBlue70: '#7e8ec3',
  primaryBlue80: '#6b7dbb',
  primaryBlue90: '#596db3',
  primaryBlue100: '#465daa',
  secondaryBlue100: '#4b72d5',
  secondaryBlue5: '#f3f6fc',
  gray1: '#fdfdfd',
  gray5: '#f4f4f4',
  gray10: '#eaeaea',
  gray20: '#d5d5d5',
  gray30: '#c0c0c0',
  gray40: '#ababab',
  gray50: '#959595',
  gray60: '#808080',
  gray70: '#6b6b6b',
  gray80: '#4c4c4c',
  gray90: '#373737',
  gray100: '#2c2c2c',
  red100: '#e20000',
  red20: '#fbd9d9',
  red5: '#fef2f2',
  brightGreen: '#3ace52',
  newGreen: '#2ecc71',
  dcsrYellow: '#ffe100',
  hospitalPurple: '#a29bfe',
  msGreen: '#01a3a4',
  black: '#000000'
}

export const customColorProfile = {
  ...baseColorProfile,
  primary: baseColorProfile.primaryBlue100,
  accent: baseColorProfile.red100,
  secondary: baseColorProfile.gray100,
  info: colors.teal.lighten1,
  warning: colors.amber.base,
  error: baseColorProfile.red100,
  caution: '#ef8973',
  success: baseColorProfile.newGreen,
  bluegreylighten05: '#eceff9'
}

export const customColorProfileKeys = Object.keys(customColorProfile)
