import { Plugin } from '@nuxt/types'
import { errorMessages } from '~/helper/messages'

export type Toast = {
  error: ({ message, error }: { message?: string; error?: any }) => void
  success: (message: string) => void
}

const plugin: Plugin = (context, inject) => {
  const toast: Toast = {
    error: ({ message, error }) => {
      let errorMessage = message || errorMessages.internalServerError
      // axiosのエラーを拾うため
      if (!message && error && error.response && error.response.data) {
        errorMessage = error.response.data
      }
      window.alert(errorMessage)
    },
    success: message => {
      context.store.dispatch('showSuccessToast', {
        message
      })
    }
  }

  context.$toast = toast
  inject('toast', toast)
}

export default plugin
