// 医療機関情報編集時に詳細編集画面を別pagesに切り出したかったのでstoreを用意
export const state = () => ({
  doneFirstFetch: false,
  hospitalId: undefined,
  image: undefined,
  type: undefined,
  name: undefined,
  furigana: undefined,
  categoryNames: undefined,
  zipcode: undefined,
  address: undefined,
  doctorCount: undefined,
  bedCountTotal: undefined,
  tel: undefined,
  tel2: undefined,
  fax: undefined,
  url: undefined,
  outpatientList: undefined,
  nurseCount: undefined,
  midwifeCount: undefined,
  pharmacistCount: undefined,
  radiologistCount: undefined,
  physicalTherapistCount: undefined,
  occupationalTherapistCount: undefined,
  speechTherapistCount: undefined,
  masuiAnnualCount: undefined,
  zenshinmasuiAnnualCount: undefined,
  koumakugaimasuiAnnualCount: undefined,
  sekizuimasuiAnnualCount: undefined,
  ctAnnualCount: undefined,
  mriAnnualCount: undefined,
  mammographyAnnualcount: undefined,
  bunbenAnnualcount: undefined,
  teiousekkaijyutsuAnnualcount: undefined,
  kinkyuuteiousekkaijyutsuAnnualcount: undefined,
  shindaikekkanshikkanRehabilitationAnnualcount: undefined,
  undoukiRehabilitationAnnualcount: undefined,
  kokyuukiRehabilitationAnnualcount: undefined,
  noukekkanshikkantouRehabilitationAnnualcount: undefined,
  bedCount1: undefined,
  bedCount2: undefined,
  bedCount3: undefined,
  bedCount4: undefined,
  bedCount5: undefined,
  bedCount6: undefined,
  bedCount7: undefined,
  bedCount8: undefined,
  vacantBeds: undefined,
  patientCount1: undefined,
  patientCount2: undefined,
  patientCount3: undefined,
  patientCount4: undefined,
  patientCount5: undefined,
  hospitalVisitDays1: undefined,
  hospitalVisitDays2: undefined,
  hospitalVisitDays3: undefined
})

export const mutations = {
  setParams(state, params) {
    Object.entries(params).forEach(([key, value]) => {
      state[key] = value
    })
  },
  clearForm(state) {
    state.hospitalId = undefined
    state.image = undefined
    state.type = undefined
    state.name = undefined
    state.furigana = undefined
    state.categoryNames = undefined
    state.zipcode = undefined
    state.address = undefined
    state.doctorCount = undefined
    state.bedCountTotal = undefined
    state.tel = undefined
    state.tel2 = undefined
    state.fax = undefined
    state.url = undefined
    state.outpatientList = undefined
    state.nurseCount = undefined
    state.midwifeCount = undefined
    state.pharmacistCount = undefined
    state.radiologistCount = undefined
    state.physicalTherapistCount = undefined
    state.occupationalTherapistCount = undefined
    state.speechTherapistCount = undefined
    state.masuiAnnualCount = undefined
    state.zenshinmasuiAnnualCount = undefined
    state.koumakugaimasuiAnnualCount = undefined
    state.sekizuimasuiAnnualCount = undefined
    state.ctAnnualCount = undefined
    state.mriAnnualCount = undefined
    state.mammographyAnnualcount = undefined
    state.bunbenAnnualcount = undefined
    state.teiousekkaijyutsuAnnualcount = undefined
    state.kinkyuuteiousekkaijyutsuAnnualcount = undefined
    state.shindaikekkanshikkanRehabilitationAnnualcount = undefined
    state.undoukiRehabilitationAnnualcount = undefined
    state.kokyuukiRehabilitationAnnualcount = undefined
    state.noukekkanshikkantouRehabilitationAnnualcount = undefined
    state.bedCount1 = undefined
    state.bedCount2 = undefined
    state.bedCount3 = undefined
    state.bedCount4 = undefined
    state.bedCount5 = undefined
    state.bedCount6 = undefined
    state.bedCount7 = undefined
    state.bedCount8 = undefined
    state.vacantBeds = undefined
    state.patientCount1 = undefined
    state.patientCount2 = undefined
    state.patientCount3 = undefined
    state.patientCount4 = undefined
    state.patientCount5 = undefined
    state.hospitalVisitDays1 = undefined
    state.hospitalVisitDays2 = undefined
    state.hospitalVisitDays3 = undefined
    state.doneFirstFetch = false
  },
  setHospital(state, hospital) {
    state.hospitalId = hospital.hospitalId
    state.image = hospital.image
    state.type = hospital.type
    state.name = hospital.name
    state.furigana = hospital.furigana
    state.categoryNames = hospital.categoryNames
    state.zipcode = hospital.zipcode
    state.address = hospital.address
    state.doctorCount = hospital.doctorCount
    state.bedCountTotal = hospital.bedCountTotal
    state.tel = hospital.tel
    state.tel2 = hospital.tel2
    state.fax = hospital.fax
    state.url = hospital.url
    state.outpatientList = hospital.outpatientList
    state.nurseCount = hospital.nurseCount
    state.midwifeCount = hospital.midwifeCount
    state.pharmacistCount = hospital.pharmacistCount
    state.radiologistCount = hospital.radiologistCount
    state.physicalTherapistCount = hospital.physicalTherapistCount
    state.occupationalTherapistCount = hospital.occupationalTherapistCount
    state.speechTherapistCount = hospital.speechTherapistCount
    state.masuiAnnualCount = hospital.masuiAnnualCount
    state.zenshinmasuiAnnualCount = hospital.zenshinmasuiAnnualCount
    state.koumakugaimasuiAnnualCount = hospital.koumakugaimasuiAnnualCount
    state.sekizuimasuiAnnualCount = hospital.sekizuimasuiAnnualCount
    state.ctAnnualCount = hospital.ctAnnualCount
    state.mriAnnualCount = hospital.mriAnnualCount
    state.mammographyAnnualcount = hospital.mammographyAnnualcount
    state.bunbenAnnualcount = hospital.bunbenAnnualcount
    state.teiousekkaijyutsuAnnualcount = hospital.teiousekkaijyutsuAnnualcount
    state.kinkyuuteiousekkaijyutsuAnnualcount =
      hospital.kinkyuuteiousekkaijyutsuAnnualcount
    state.shindaikekkanshikkanRehabilitationAnnualcount =
      hospital.shindaikekkanshikkanRehabilitationAnnualcount
    state.undoukiRehabilitationAnnualcount =
      hospital.undoukiRehabilitationAnnualcount
    state.kokyuukiRehabilitationAnnualcount =
      hospital.kokyuukiRehabilitationAnnualcount
    state.noukekkanshikkantouRehabilitationAnnualcount =
      hospital.noukekkanshikkantouRehabilitationAnnualcount
    state.bedCount1 = hospital.bedCount1
    state.bedCount2 = hospital.bedCount2
    state.bedCount3 = hospital.bedCount3
    state.bedCount4 = hospital.bedCount4
    state.bedCount5 = hospital.bedCount5
    state.bedCount6 = hospital.bedCount6
    state.bedCount7 = hospital.bedCount7
    state.bedCount8 = hospital.bedCount8
    state.vacantBeds = hospital.vacantBeds
    state.patientCount1 = hospital.patientCount1
    state.patientCount2 = hospital.patientCount2
    state.patientCount3 = hospital.patientCount3
    state.patientCount4 = hospital.patientCount4
    state.patientCount5 = hospital.patientCount5
    state.hospitalVisitDays1 = hospital.hospitalVisitDays1
    state.hospitalVisitDays2 = hospital.hospitalVisitDays2
    state.hospitalVisitDays3 = hospital.hospitalVisitDays3
    state.doneFirstFetch = true
  }
}

export const actions = {
  async getHospital({ commit }, { id }) {
    const { data } = await this.$axios.get(`/api/hospitals/${id}`)
    commit('setHospital', data)
  }
}
